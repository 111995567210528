import { __awaiter, __generator } from "tslib";
import { app as api } from '@ekuaibao/whispered';
import { period, opportunity, periodMap, opportunityMap } from './utils/enum';
export default [
    {
        id: '@supplier-file',
        path: '/supplier-file',
        ref: '/',
        onload: function () { return import('./SupplierFileView'); },
        onfirst: function (app) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, app.store
                            .dynamic('@supplier-file', function () { return import('./supplier-file.store'); })
                            .load('@supplier-file')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        // store: () => import('./supplier-file.store'),
        'get:supplier': function (params) {
            return api.store.dispatch('@supplier-file/getSupplierDetail')(params);
        },
        'get:supplier:template': function () {
            api.store.dispatch('@supplier-file/getSupplierTemplate')();
        },
        'get:supplier:enums': function () {
            return { period: period, opportunity: opportunity, periodMap: periodMap, opportunityMap: opportunityMap };
        },
        'get:no:unify:invoice:supplier': function (query) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.store.dispatch('@supplier-file/getNoUnifyInvoiceSupplier')(query)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); },
    },
    {
        point: '@@menus',
        onload: function () { return [
            {
                id: 'supplier-file',
                pId: 'supplier',
                permissions: ['SYS_ADMIN'],
                weight: 10,
                label: '',
                href: '/supplier-file',
                icon: 'supplier-file',
            },
        ]; },
    },
    {
        point: '@@layers',
        prefix: '@supplier-file',
        onload: function () { return import('./layers'); },
    },
];
