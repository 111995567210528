export var opportunity = [
    {
        name: 'UNIFY',
        label: i18n.get('结算'),
    },
    {
        name: 'NOSETTLEMENT',
        label: i18n.get('无需结算'),
    },
];
export var period = [
    {
        name: 'WEEK',
        label: i18n.get('周'),
    },
    {
        name: 'BIWEEKLY',
        label: i18n.get('双周'),
    },
    {
        name: 'MONTH',
        label: i18n.get('月'),
    },
    {
        name: 'SEASON',
        label: i18n.get('季度'),
    },
    {
        name: 'HALFYEAR',
        label: i18n.get('半年'),
    },
    {
        name: 'YEAR',
        label: i18n.get('年'),
    },
    {
        name: 'IRREGULAR',
        label: i18n.get('不定期'),
    },
];
export var opportunityMap = {
    UNIFY: i18n.get('结算'),
};
export var periodMap = {
    WEEK: i18n.get('周'),
    BIWEEKLY: i18n.get('双周'),
    MONTH: i18n.get('月'),
    SEASON: i18n.get('季度'),
    HALFYEAR: i18n.get('半年'),
    YEAR: i18n.get('年'),
    IRREGULAR: i18n.get('不定期'),
};
export var SourceTypes = [
    {
        value: '行程管理',
        label: i18n.get('行程管理'),
        children: [
            { value: '合思商城', label: i18n.get('合思商城') },
            { value: '携程商旅', label: i18n.get('携程商旅') },
            { value: '阿里商旅', label: i18n.get('阿里商旅') },
            { value: '差旅壹号', label: i18n.get('差旅壹号') },
            { value: '滴滴企业版', label: i18n.get('滴滴企业版') },
        ],
    },
];
export var SettlementTypes = [
    { label: i18n.get('授信'), value: '授信' },
    { label: i18n.get('预存'), value: '预存' },
];
